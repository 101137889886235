import { createSlice } from "@reduxjs/toolkit";
import {
  fetchLandingPage,
  // joinWaitlistThunk,
  fetchApiPropertyDataThunk,
} from "../thunks/dataThunks";

const dataSlice = createSlice({
  name: "data",
  initialState: {
    // Data Fields
    apiData: null,
    propertyListingData: [],
    waitlist: [], // Ensure this line is present

    // Status Fields for Landing Page
    landingPageStatus: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    landingPageError: null,

    // Status Fields for Property Data
    propertyDataStatus: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    propertyDataError: null,

    // Status Fields for Waitlist
    loading: false,
    error: null,
  },
  reducers: {
    // You can add synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchLandingPage Thunk
      .addCase(fetchLandingPage.pending, (state) => {
        state.landingPageStatus = "loading";
        state.landingPageError = null;
      })
      .addCase(fetchLandingPage.fulfilled, (state, action) => {
        state.landingPageStatus = "succeeded";
        state.apiData = action.payload;
      })
      .addCase(fetchLandingPage.rejected, (state, action) => {
        state.landingPageStatus = "failed";
        state.landingPageError = action.payload || action.error.message;
      })

      // Handle joinWaitlistThunk Thunk
      // .addCase(joinWaitlistThunk.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(joinWaitlistThunk.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.waitlist.push(action.payload.email); // Push the email to the waitlist array
      // })
      // .addCase(joinWaitlistThunk.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload || action.error.message;
      // })

      // Handle fetchApiPropertyDataThunk Thunk
      .addCase(fetchApiPropertyDataThunk.pending, (state) => {
        console.log("fetchApiPropertyDataThunk is pending...");
        state.propertyDataStatus = "loading";
        state.propertyDataError = null;
      })
      .addCase(fetchApiPropertyDataThunk.fulfilled, (state, action) => {
        console.log(
          "fetchApiPropertyDataThunk was successful:",
          action.payload
        );
        state.propertyDataStatus = "succeeded";
        state.propertyListingData = action.payload;
      })
      .addCase(fetchApiPropertyDataThunk.rejected, (state, action) => {
        console.log(
          "fetchApiPropertyDataThunk failed:",
          action.payload || action.error.message
        );
        state.propertyDataStatus = "failed";
        state.propertyDataError = action.payload || action.error.message;
      });
  },
});

export default dataSlice.reducer;
