import { createSlice } from "@reduxjs/toolkit";
import { submitOnboarding } from "../thunks/onboardingThunk";

const initialState = {
  answers: [],
  isCompleted: false,
  error: null,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitOnboarding.pending, (state) => {
        state.error = null;
      })
      .addCase(submitOnboarding.fulfilled, (state, action) => {
        state.answers = action.payload.answers;
        state.isCompleted = true;
      })
      .addCase(submitOnboarding.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default onboardingSlice.reducer;
