import React from "react";
import { motion } from "framer-motion";
import dashboard from "../../assets/updated-dashboard.png";

const FloatingImage = () => {
  return (
    <div className="relative flex justify-center">
      <motion.img
        src={dashboard}
        alt="Dashboard"
        className="floating-image shadow-lg"
        style={{
          width: "100%",
          maxWidth: "1200px",
          transition: "transform 0.3s ease",
          border: "2px solid #0A2342", // Changed border color to the specified dark blue
        }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      />
      <style>
        {`
        @media (max-width: 768px) {
          .floating-image {
            transform-origin: bottom left; /* Anchor zoom to the bottom-left corner */
            transform: scale(1.2) translate(0%, 20%); /* Zoom by 20% and shift up */
          }
        }
      `}
      </style>
    </div>
  );
};

export default FloatingImage;
