import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import dataReducer from "./slices/dataSlice";
import authReducer from "./slices/authSlice";
import onboardingReducer from "./slices/onboardingSlice";
import pdfsReducer from "./slices/pdfsSlice";
const persistConfig = {
  key: "propulsionai",
  storage,
};

const rootReducer = combineReducers({
  data: dataReducer,
  auth: authReducer,
  onboarding: onboardingReducer,
  pdfs: pdfsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export * from "./thunks/dataThunks";
export * from "./thunks/authThunk";
export * from "./thunks/onboardingThunk";
export * from "./thunks/pdfsThunk";
