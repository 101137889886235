// src/components/dummyProperties.js

import house1 from "../../assets/house 1.jpeg";
import house2 from "../../assets/house 2.png";
import house3 from "../../assets/house 3.jpeg";
import house4 from "../../assets/house 4.jpeg";
import house5 from "../../assets/house 5.jpeg";
import house6 from "../../assets/house 6.jpeg";
import house7 from "../../assets/house 7.jpeg";
import house8 from "../../assets/house 8.jpeg";
import house9 from "../../assets/house 9.jpeg";
import house10 from "../../assets/house 10.png";

const dummyProperties = [
  {
    name: "Modern Condo",
    address: "123 King St W",
    longitude: -79.3871,
    latitude: 43.6426,
    price: 1018000,
    bedrooms: 2,
    bathrooms: 2,
    image: house1,
    status: "For Sale",
  },
  {
    name: "Luxury Apartment",
    address: "200 Bay St",
    longitude: -79.3802,
    latitude: 43.6489,
    price: 628000,
    bedrooms: 1,
    bathrooms: 1,
    image: house2,
    status: "For Sale",
  },
  {
    name: "Spacious Condo",
    address: "300 Front St W",
    longitude: -79.3848,
    latitude: 43.6455,
    price: 995000,
    bedrooms: 2,
    bathrooms: 2,
    image: house3,
    status: "For Sale",
  },
  {
    name: "Family Home",
    address: "400 King St W",
    longitude: -79.3875,
    latitude: 43.6421,
    price: 1200000,
    bedrooms: 4,
    bathrooms: 3,
    image: house4,
    status: "For Sale",
  },
  {
    name: "Suburban House",
    address: "500 Queen St W",
    longitude: -79.396,
    latitude: 43.646,
    price: 950000,
    bedrooms: 3,
    bathrooms: 2,
    image: house5,
    status: "For Sale",
  },
  {
    name: "Townhouse",
    address: "600 Spadina Ave",
    longitude: -79.4,
    latitude: 43.65,
    price: 750000,
    bedrooms: 3,
    bathrooms: 2,
    image: house6,
    status: "For Sale",
  },
  {
    name: "Investment Property",
    address: "700 Adelaide St W",
    longitude: -79.3965,
    latitude: 43.645,
    price: 1195000,
    bedrooms: 4,
    bathrooms: 4,
    image: house7,
    status: "For Sale",
  },
  {
    name: "Downtown Condo",
    address: "800 York St",
    longitude: -79.392,
    latitude: 43.6505,
    price: 499000,
    bedrooms: 1,
    bathrooms: 1,
    image: house8,
    status: "For Sale",
  },
  {
    name: "Student Rental",
    address: "900 Richmond St W",
    longitude: -79.397,
    latitude: 43.6445,
    price: 600000,
    bedrooms: 5,
    bathrooms: 2,
    image: house9,
    status: "For Sale",
  },
  {
    name: "Luxury Condo",
    address: "100 Harbour St, Toronto",
    longitude: -79.3755,
    latitude: 43.6426,
    price: 1500000,
    bedrooms: 2,
    bathrooms: 2,
    image: house10,
    status: "For Sale",
  },
  {
    name: "Upscale Apartment",
    address: "1000 Bloor St W",
    longitude: -79.41,
    latitude: 43.661,
    price: 850000,
    bedrooms: 1,
    bathrooms: 1,
    image: house1,
    status: "For Sale",
  },
  {
    name: "Vito's Crib",
    address: "10 Capreol Crt",
    longitude: -79.3957,
    latitude: 43.6401,
    price: 720000,
    bedrooms: 1,
    bathrooms: 1,
    image: house2,
    status: "For Sale",
  },
  {
    name: "Charming Bungalow",
    address: "101 Ossington Ave",
    longitude: -79.4197,
    latitude: 43.6476,
    price: 650000,
    bedrooms: 3,
    bathrooms: 2,
    image: house3,
    status: "For Sale",
  },
  {
    name: "Arc'teryx",
    address: "50 Bloor St W",
    longitude: -79.41,
    latitude: 43.661,
    price: 550000,
    bedrooms: 2,
    bathrooms: 2,
    image: house4,
    status: "For Sale",
  },
  {
    name: "Fit 4 Less",
    address: "21 McCaul St",
    longitude: -79.3899,
    latitude: 43.6510, // Corrected latitude for 21 McCaul St
    price: 550000,
    bedrooms: 2,
    bathrooms: 2,
    image: house4,
    status: "For Sale",
  },

  {
    name: "Modern Semi-Detached",
    address: "456 Yonge St",
    longitude: -79.3806,
    latitude: 43.6708,
    price: 780000,
    bedrooms: 3,
    bathrooms: 2,
    image: house5,
    status: "For Sale",
  },
];

export default dummyProperties;
