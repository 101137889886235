import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const MAPTILER_API_KEY = process.env.REACT_APP_MAPTILER_API_KEY;

const Map = forwardRef(({ locations, zoom }, ref) => {
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);

  useImperativeHandle(ref, () => ({
    flyTo: (options) => {
      if (mapInstance.current) {
        mapInstance.current.flyTo(options);
      }
    },
  }));

  useEffect(() => {
    if (locations.length === 0) return;

    const map = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets/style.json?key=${MAPTILER_API_KEY}`,
      center: [locations[0].longitude, locations[0].latitude],
      zoom: zoom,
      pitch: 20, // Tilt the map for 3D view
      bearing: 0, // Rotate the map slightly
    });

    mapInstance.current = map;

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new maplibregl.NavigationControl(), "top-right");

    // Add markers for all locations
    locations.forEach((location) => {
      new maplibregl.Marker()
        .setLngLat([location.longitude, location.latitude])
        .setPopup(
          new maplibregl.Popup().setHTML(
            `<h3>${location.name}</h3><p>${location.address}</p>`
          )
        )
        .addTo(map);
    });

    // Fit the map to show all markers
    const bounds = new maplibregl.LngLatBounds();
    locations.forEach((location) => {
      bounds.extend([location.longitude, location.latitude]);
    });
    map.fitBounds(bounds, { padding: 50 });

    // Clean up on unmount
    return () => map.remove();
  }, [locations, zoom]);

  return <div ref={mapContainer} style={{ width: "100%", height: "400px" }} />;
});

export default Map;
