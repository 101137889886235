import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Coffee, ChevronDown } from "lucide-react";
import house10 from "../assets/house 10.png";
import Map from "../components/maplibre/Map";

const PropertyDetailsPage = () => {
  const user = useSelector((state) => state.auth.user);

  const [activeSection, setActiveSection] = useState("details");
  const [property, setProperty] = useState(null);
  const sectionRefs = {
    details: useRef(null),
    neighborhood: useRef(null),
    schools: useRef(null),
    amenities: useRef(null),
    comparables: useRef(null),
    financials: useRef(null),
  };

  const [openNeighborhood, setOpenNeighborhood] = useState(true);
  const [openSchools, setOpenSchools] = useState(true);
  const [openAmenities, setOpenAmenities] = useState(true);
  const [openComparable, setOpenComparable] = useState(true);
  const [openFinancials, setOpenFinancials] = useState(true);

  useEffect(() => {
    // Simulating an API call to fetch property data
    const fetchPropertyData = async () => {
      // Replace this with your actual API call
      const mockData = {
        address: "123 Main St, Toronto, ON, M5V 2K7",
        price: 1250000,
        bedrooms: 3,
        bathrooms: 2,
        sqft: 2000,
        type: "Detached",
        yearBuilt: 2015,
        description:
          "Beautiful detached home in the heart of Toronto. Recently renovated with modern finishes and a spacious backyard.",
        features: [
          "Hardwood floors",
          "Stainless steel appliances",
          "Finished basement",
          "Backyard deck",
        ],
        neighborhood: {
          name: "Downtown West",
          walkScore: 92,
          transitScore: 95,
          bikeScore: 80,
        },
        schools: [
          {
            name: "Downtown Elementary",
            type: "Public",
            grades: "K-6",
            rating: 8.5,
            distance: 0.5,
          },
          {
            name: "Central High School",
            type: "Public",
            grades: "9-12",
            rating: 9.0,
            distance: 1.2,
          },
        ],
        amenities: [
          { name: "Grocery Store", distance: 0.3 },
          { name: "Park", distance: 0.5 },
          { name: "Gym", distance: 0.7 },
          { name: "Restaurant", distance: 0.2 },
        ],
        comparables: [
          {
            address: "456 Elm St",
            price: 1200000,
            bedrooms: 3,
            bathrooms: 2,
            sqft: 1950,
          },
          {
            address: "789 Oak Ave",
            price: 1300000,
            bedrooms: 4,
            bathrooms: 2,
            sqft: 2100,
          },
        ],
        financials: {
          estimatedMortgage: 5000,
          propertyTax: 500,
          insurance: 150,
          utilities: 200,
          maintenanceFee: 0,
        },
      };

      // Simulate network delay
      setTimeout(() => setProperty(mockData), 1000);
    };

    fetchPropertyData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100;

      Object.entries(sectionRefs).forEach(([section, ref]) => {
        if (ref.current && scrollPosition >= ref.current.offsetTop) {
          setActiveSection(section);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (section) => {
    sectionRefs[section].current?.scrollIntoView({ behavior: "smooth" });
  };

  if (!property) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <div className="loader"></div>
          <h2 className="mt-4 text-lg font-semibold text-[#0A2342]">
            Gathering property info...
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="sticky top-0 z-10 bg-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <h1 className="text-2xl font-bold mb-2 text-[#0A2342]">
            {property.address}
          </h1>
          <div className="flex items-center space-x-4 mb-4">
            <span className="bg-[#0A2342] text-white text-lg px-3 py-1">
              ${property.price?.toLocaleString() ?? "N/A"}
            </span>
            <span className="text-gray-600">
              {property.bedrooms} beds • {property.bathrooms} baths •{" "}
              {property.sqft} sqft
            </span>
          </div>
          <div className="flex space-x-4 overflow-x-auto py-1">
            {Object.keys(sectionRefs).map((section) => (
              <motion.button
                key={section}
                onClick={() => scrollToSection(section)}
                className={`px-4 py-2 whitespace-nowrap ${
                  activeSection === section
                    ? "bg-[#0A2342] text-white"
                    : "bg-white text-[#0A2342] border border-[#0A2342]"
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}>
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </motion.button>
            ))}
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div className="md:col-span-2">
            <img src={house10} alt="Property" className="w-full h-auto" />
          </div>
          <div>
            <motion.div
              className="bg-white p-6"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}>
              <h2 className="text-xl font-semibold mb-4 text-[#0A2342]">
                Contact Agent
              </h2>
              <form>
                <div className="space-y-4">
                  <input
                    type="text"
                    placeholder="Name"
                    className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                    defaultValue={`${user.firstName} ${user.lastName}`}
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                    defaultValue={user.username}
                  />
                  <input
                    type="tel"
                    placeholder="Phone"
                    className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"
                  />
                  <textarea
                    placeholder="Message"
                    rows="4"
                    className="w-full p-2 border border-gray-300 focus:border-[#0A2342] focus:outline-none"></textarea>
                  <motion.button
                    className="w-full bg-[#0A2342] text-white py-2"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}>
                    Send Message
                  </motion.button>
                </div>
              </form>
            </motion.div>
          </div>
        </div>

        <div className="mb-8">
          <Map
            locations={[
              {
                name: "Location 1",
                address: "100 King St W, Toronto, ON",
                longitude: -79.3832,
                latitude: 43.65,
              },
              {
                name: "Location 2",
                address: "456 Queen St W, Toronto, ON",
                longitude: -79.4002,
                latitude: 43.646,
              },
            ]}
            zoom={12} // Increased zoom level for better visibility
          />
        </div>

        <div className="space-y-8">
          <motion.div
            ref={sectionRefs.details}
            className="bg-white p-6"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <h2 className="text-xl font-semibold mb-4 text-[#0A2342]">
              Property Details
            </h2>
            <p className="mb-4">{property.description}</p>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="font-semibold text-[#0A2342]">Property Type</h3>
                <p>{property.type}</p>
              </div>
              <div>
                <h3 className="font-semibold text-[#0A2342]">Year Built</h3>
                <p>{property.yearBuilt}</p>
              </div>
            </div>
            <h3 className="font-semibold mt-4 mb-2 text-[#0A2342]">Features</h3>
            <ul className="list-disc pl-5">
              {property.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </motion.div>

          <motion.div
            ref={sectionRefs.neighborhood}
            className="bg-white p-6"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <h2
              className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
              onClick={() => setOpenNeighborhood(!openNeighborhood)}>
              {property.neighborhood?.name ?? "Neighborhood"}
              <ChevronDown
                className={`ml-2 transform transition-transform ${
                  openNeighborhood ? "rotate-180" : "rotate-0"
                }`}></ChevronDown>
            </h2>
            {openNeighborhood && (
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <h3 className="font-semibold text-[#0A2342]">Walk Score</h3>
                  <div className="w-full bg-gray-200 h-2.5 mt-2">
                    <div
                      className="bg-[#0A2342] h-2.5"
                      style={{
                        width: `${property.neighborhood?.walkScore ?? 0}%`,
                      }}></div>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">
                    {property.neighborhood?.walkScore ?? "N/A"}/100
                  </p>
                </div>
                <div>
                  <h3 className="font-semibold text-[#0A2342]">
                    Transit Score
                  </h3>
                  <div className="w-full bg-gray-200 h-2.5 mt-2">
                    <div
                      className="bg-[#0A2342] h-2.5"
                      style={{
                        width: `${property.neighborhood?.transitScore ?? 0}%`,
                      }}></div>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">
                    {property.neighborhood?.transitScore ?? "N/A"}/100
                  </p>
                </div>
                <div>
                  <h3 className="font-semibold text-[#0A2342]">Bike Score</h3>
                  <div className="w-full bg-gray-200 h-2.5 mt-2">
                    <div
                      className="bg-[#0A2342] h-2.5"
                      style={{
                        width: `${property.neighborhood?.bikeScore ?? 0}%`,
                      }}></div>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">
                    {property.neighborhood?.bikeScore ?? "N/A"}/100
                  </p>
                </div>
              </div>
            )}
          </motion.div>

          <motion.div
            ref={sectionRefs.schools}
            className="bg-white p-6"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <h2
              className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
              onClick={() => setOpenSchools(!openSchools)}>
              Nearby Schools
              <ChevronDown
                className={`ml-2 transform transition-transform ${
                  openSchools ? "rotate-180" : "rotate-0"
                }`}></ChevronDown>
            </h2>
            {openSchools && (
              <>
                {property.schools?.map((school, index) => (
                  <div
                    key={index}
                    className="mb-4 pb-4 border-b last:border-b-0">
                    <h3 className="font-semibold text-[#0A2342]">
                      {school.name}
                    </h3>
                    <p className="text-sm text-gray-600">
                      {school.type} • Grades {school.grades} • {school.distance}{" "}
                      km
                    </p>
                    <div className="flex items-center mt-2">
                      <div className="w-1/2 bg-gray-200 h-2.5 mr-2">
                        <div
                          className="bg-[#0A2342] h-2.5"
                          style={{
                            width: `${(school.rating ?? 0) * 10}%`,
                          }}></div>
                      </div>
                      <span className="text-sm font-semibold">
                        {school.rating ?? "N/A"}/10
                      </span>
                    </div>
                  </div>
                )) ?? <p>No school information available.</p>}
              </>
            )}
          </motion.div>

          <motion.div
            ref={sectionRefs.amenities}
            className="bg-white p-6"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <h2
              className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
              onClick={() => setOpenAmenities(!openAmenities)}>
              Nearby Amenities
              <ChevronDown
                className={`ml-2 transform transition-transform ${
                  openAmenities ? "rotate-180" : "rotate-0"
                }`}></ChevronDown>
            </h2>
            {openAmenities && (
              <div className="grid grid-cols-2 gap-4">
                {property.amenities?.map((amenity, index) => (
                  <div key={index} className="flex items-center">
                    <Coffee className="mr-2 text-[#0A2342]" />
                    <div>
                      <h3 className="font-semibold text-[#0A2342]">
                        {amenity.name}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {amenity.distance} km
                      </p>
                    </div>
                  </div>
                )) ?? <p>No amenity information available.</p>}
              </div>
            )}
          </motion.div>

          <motion.div
            ref={sectionRefs.comparables}
            className="bg-white p-6"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <h2
              className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
              onClick={() => setOpenComparable(!openComparable)}>
              Comparable Properties
              <ChevronDown
                className={`ml-2 transform transition-transform ${
                  openComparable ? "rotate-180" : "rotate-0"
                }`}></ChevronDown>
            </h2>
            {openComparable && (
              <div>
                {property.comparables?.length > 0 ? (
                  property.comparables.map((comp, index) => (
                    <div
                      key={index}
                      className="mb-4 pb-4 border-b last:border-b-0">
                      <h3 className="font-semibold text-[#0A2342]">
                        {comp.address}
                      </h3>
                      <p className="text-sm text-gray-600">
                        ${comp.price?.toLocaleString() ?? "N/A"} •{" "}
                        {comp.bedrooms} beds • {comp.bathrooms} baths •{" "}
                        {comp.sqft} sqft
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No comparable properties available.</p>
                )}
              </div>
            )}
          </motion.div>

          <motion.div
            ref={sectionRefs.financials}
            className="bg-white p-6"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <h2
              className="text-xl font-semibold mb-4 text-[#0A2342] cursor-pointer flex items-center"
              onClick={() => setOpenFinancials(!openFinancials)}>
              Financial Analysis
              <ChevronDown
                className={`ml-2 transform transition-transform ${
                  openFinancials ? "rotate-180" : "rotate-0"
                }`}
              />
            </h2>
            {openFinancials && (
              <div className="space-y-4">
                {Object.entries(property.financials || {}).map(
                  ([key, value]) => (
                    <div
                      className="flex justify-between items-center"
                      key={key}>
                      <span>
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}
                      </span>
                      <span className="font-semibold text-[#0A2342]">
                        ${value ?? "N/A"}/mo
                      </span>
                    </div>
                  )
                )}
                <div className="flex justify-between items-center font-bold text-lg pt-4 border-t">
                  <span>Total Monthly Cost</span>
                  <span className="text-[#0A2342]">
                    $
                    {property.financials
                      ? Object.values(property.financials).reduce(
                          (a, b) => a + b,
                          0
                        )
                      : "N/A"}
                    /mo
                  </span>
                </div>
              </div>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsPage;
