import React, { useEffect, useState, useRef } from "react";
import { Switch } from "@headlessui/react";
import { MapPinIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Map from "../components/maplibre/Map";
import * as Slider from "@radix-ui/react-slider";
import dummyProperties from "../components/properties/dummyProperties";

export default function DiscoverPage() {
  const [priceRange, setPriceRange] = useState([0, 2000000]);
  const [bedrooms, setBedrooms] = useState(1);
  const [propertyType, setPropertyType] = useState("House");
  const [listingType, setListingType] = useState("Sale");
  const [squareFootage, setSquareFootage] = useState(0);
  const [showSold, setShowSold] = useState(false);
  const mapRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Default to closed for mobile

  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          console.log({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, []);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  return (
    <div className="h-screen flex bg-gray-50">
      {/* Mobile Menu Toggle */}
      <div className="md:hidden absolute left-4 z-50" style={{ top: "80px" }}>
        <button
          onClick={toggleSidebar}
          className="text-gray-700 p-2 rounded-full bg-white shadow-md">
          {isSidebarOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
      </div>

      {/* Sidebar (Filters) */}
      <div
        className={`${
          isSidebarOpen ? "block" : "hidden"
        } md:block fixed md:relative z-40 bg-white shadow-md p-4 pt-5 w-full md:w-1/4 lg:w-1/5 h-full md:h-auto`}>
        <h1 className="text-2xl font-semibold text-[#0A2342] mb-4 md:ml-0 ml-12">
          Discover Your Dream Home
        </h1>
        <div className="flex flex-col space-y-4">
          {/* Price Range */}
          <div className="flex flex-col">
            <label
              htmlFor="price-range"
              className="text-sm font-medium text-gray-700">
              Price Range
            </label>
            <Slider.Root
              className="relative flex items-center select-none touch-none w-full h-5"
              value={priceRange}
              onValueChange={setPriceRange}
              min={0}
              max={2000000}
              step={50000}>
              <Slider.Track className="bg-gray-300 relative grow rounded-full h-[3px]">
                <Slider.Range className="absolute bg-[#0A2342] rounded-full h-full" />
              </Slider.Track>
              <Slider.Thumb className="block w-5 h-5 bg-white border-2 border-gray-300 shadow-lg rounded-full focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
              <Slider.Thumb className="block w-5 h-5 bg-white border-2 border-gray-300 shadow-lg rounded-full focus:outline-none focus:ring-2 focus:ring-[#0A2342]" />
            </Slider.Root>
            <div className="text-sm text-gray-500 mt-1">
              ${priceRange[0].toLocaleString()} - $
              {priceRange[1].toLocaleString()}
            </div>
          </div>

          {/* Bedrooms */}
          <div className="flex flex-col">
            <label
              htmlFor="bedrooms"
              className="text-sm font-medium text-gray-700">
              Bedrooms
            </label>
            <input
              id="bedrooms"
              type="number"
              value={bedrooms}
              onChange={(e) => setBedrooms(parseInt(e.target.value))}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>

          {/* Property Type */}
          <div className="flex flex-col">
            <label
              htmlFor="property-type"
              className="text-sm font-medium text-gray-700">
              Property Type
            </label>
            <select
              id="property-type"
              value={propertyType}
              onChange={(e) => setPropertyType(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full">
              <option value="House">House</option>
              <option value="Condo">Condo</option>
              <option value="Apartment">Apartment</option>
              <option value="Loft">Loft</option>
            </select>
          </div>

          {/* Listing Type */}
          <div className="flex flex-col">
            <label
              htmlFor="listing-type"
              className="text-sm font-medium text-gray-700">
              Listing Type
            </label>
            <select
              id="listing-type"
              value={listingType}
              onChange={(e) => setListingType(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full">
              <option value="Sale">For Sale</option>
              <option value="Rent">For Rent</option>
              <option value="Resale">Resale</option>
              <option value="Consignment">Consignment</option>
            </select>
          </div>

          {/* Square Footage */}
          <div className="flex flex-col">
            <label
              htmlFor="square-footage"
              className="text-sm font-medium text-gray-700">
              Square Footage
            </label>
            <input
              id="square-footage"
              type="number"
              value={squareFootage}
              onChange={(e) => setSquareFootage(parseInt(e.target.value))}
              className="border border-gray-300 rounded-md p-2 w-full"
            />
          </div>

          {/* Show Sold Toggle */}
          <div className="flex items-center space-x-2">
            <Switch
              id="show-sold"
              checked={showSold}
              onChange={setShowSold}
              className={`${
                showSold ? "bg-[#0A2342]" : "bg-gray-200"
              } relative inline-flex items-center h-6 rounded-full w-11`}>
              <span className="sr-only">Show Sold</span>
              <span
                className={`${
                  showSold ? "translate-x-6" : "translate-x-1"
                } inline-block w-4 h-4 transform bg-white rounded-full transition`}
              />
            </Switch>
            <label
              htmlFor="show-sold"
              className="text-sm font-medium text-gray-700">
              Show Sold
            </label>
          </div>

          {/* Current Location Button */}
          <button
            className="w-full bg-gray-100 hover:bg-gray-200 border border-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-md flex items-center justify-center"
            onClick={() => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const { latitude, longitude } = position.coords;
                    if (mapRef.current) {
                      mapRef.current.flyTo({
                        center: [longitude, latitude],
                        zoom: 15,
                        speed: 1,
                        curve: 1,
                        // duration: 1,
                        essential: true,
                      });
                    }
                  },
                  (error) => {
                    console.error("Error getting location:", error);
                  }
                );
              } else {
                alert("Geolocation is not supported by this browser.");
              }
            }}>
            <MapPinIcon className="mr-2 h-4 w-4" /> Current Location
          </button>
        </div>
      </div>

      {/* Map Section */}
      <div
        className={`flex-grow h-full transition-all ${
          isSidebarOpen ? "ml-64" : "ml-0"
        } md:ml-0`}>
        <Map
          ref={mapRef}
          locations={dummyProperties}
          zoom={12}
          className="h-full"
        />
      </div>
    </div>
  );
}
