import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoutThunk } from "../store/thunks/authThunk";
import { useDispatch, useSelector } from "react-redux"; // Added useSelector
import { useAuth0 } from "@auth0/auth0-react";
import { motion } from "framer-motion";

import { Disclosure, Menu } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

import logo from "../assets/logo-small.png";

const navigation = [
  {
    name: "Home",
    href: "/dashboard",
    current: window.location.pathname === "/dashboard",
  },
  {
    name: "Discover",
    href: "/discover",
    current: window.location.pathname === "/discover",
  },
  {
    name: "Realtors",
    href: "/realtors",
    current: window.location.pathname === "/realtors",
  },
  {
    name: "Learn",
    href: "/learn",
    current: window.location.pathname === "/learn",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch user from the store
  const user = useSelector((state) => state.auth.user); // Assuming user is stored in auth slice

  const handleLogout = async (e) => {
    e.preventDefault();
    navigate("/loading", {
      state: {
        message: "Logging out...",
        duration: 1500, // Duration for the loading screen
        redirectTo: "/",
      },
    });
    try {
      await dispatch(logoutThunk()).unwrap();
      navigate("/loading", {
        state: {
          message: "Logged out successfully",
          duration: 1000, // Duration for the success message
          redirectTo: "/",
        },
      });
    } catch (err) {
      console.error("Failed to logout: ", err);
    }
  };

  const userNavigation = [
    { name: "Settings", href: "/settings" },
    { name: "Onboarding", href: "/onboarding" },
    { name: "Sign out", href: "#", onClick: handleLogout },
  ];

  return (
    <Disclosure
      as="nav"
      className="bg-white border-b border-[#0A2342] shadow-lg w-full">
      {({ open }) => (
        <>
          <div className="mx-auto w-full lg:w-5/5 px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <Link
                  to="/dashboard"
                  onClick={() => navigate("/dashboard")}
                  className="flex flex-shrink-0 items-center">
                  <img src={logo} className="h-12 w-auto" alt="Synergy AI" />
                </Link>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-6">
                  {navigation.map((item) => (
                    <motion.a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "text-[#0A2342] font-bold"
                          : "text-gray-500 hover:text-[#0A2342]",
                        "inline-flex items-center text-sm pt-1"
                      )}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}>
                      {item.name}
                    </motion.a>
                  ))}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <motion.button
                  type="button"
                  className="relative rounded-full bg-white p-1 text-gray-400 hover:text-[#0A2342] focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}>
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </motion.button>

                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <div className="h-8 w-8 rounded-full bg-white flex items-center justify-center text-[#0A2342] border border-[#0A2342]">
                        {user?.firstName?.charAt(0)}
                        {user?.lastName?.charAt(0)}
                      </div>
                    </Menu.Button>
                  </div>
                  <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active
                                ? "bg-gray-300 font-semibold text-[#0A2342] text-md"
                                : "text-gray-500",
                              "block px-4 py-2 text-sm"
                            )}>
                            <a
                              href={item.href}
                              onClick={item.onClick}
                              className="block w-full h-full">
                              {item.name}
                            </a>
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-200 hover:text-[#0A2342] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#0A2342]">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-50 text-[#0A2342] font-semibold text-lg"
                      : "text-gray-500 hover:bg-gray-200 hover:text-[#0A2342]",
                    "block px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}>
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 rounded-full border border-[#0A2342] flex items-center justify-center bg-white text-[#0A2342]">
                    {user?.firstName?.charAt(0)}
                    {user?.lastName?.charAt(0)}
                  </div>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-[#0A2342]">
                    {user.firstName} {user.lastName}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user.email}
                  </div>
                </div>
                <motion.button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-[#0A2342] focus:outline-none focus:ring-2 focus:ring-[#0A2342] focus:ring-offset-2"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}>
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </motion.button>
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    onClick={item.onClick}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-200 hover:text-[#0A2342]">
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
