import React from "react";

import PropertySection from "../components/properties/PropertySection";
import FindYourDream from "../components/FindYourDream";

import dummyProperties from "../components/properties/dummyProperties";

const getRandomProperties = (num) => {
  const shuffled = [...dummyProperties].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};

const DashboardPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <main className="container mx-auto px-4 py-8">
        <FindYourDream />

        <PropertySection
          title="Personalized Recommendations"
          properties={getRandomProperties(5)} // Adjust the number as needed
        />

        <PropertySection
          title="Best for Schools"
          properties={getRandomProperties(5)} // Adjust the number as needed
        />
        <PropertySection
          title="Best for Rental Investment"
          properties={getRandomProperties(5)} // Adjust the number as needed
        />
        <PropertySection
          title="Best Amenities"
          properties={getRandomProperties(5)} // Adjust the number as needed
        />
        <PropertySection
          title="Similar to Favorites"
          properties={getRandomProperties(5)} // Adjust the number as needed
        />
      </main>
    </div>
  );
};

export default DashboardPage;
