import React from "react";
import { motion } from "framer-motion";
import PropertyGridTile from "./PropertyGridTile";

const PropertySection = ({ title, properties }) => {
  const handlePropertyClick = (property) => {
    window.open("/propertydetails", "_blank");
  };

  return (
    <motion.section
      className="mb-12"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}>
      <h2 className="text-2xl font-bold mb-6 text-[#0A2342]">{title}</h2>

      <div className="container mx-auto">
        <div className="flex overflow-x-auto space-x-4 scrollbar-show md:px-1.5">
          {properties.map((property, index) => (
            <div
              className="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 py-2"
              key={index}>
              <PropertyGridTile property={property} />
            </div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default PropertySection;
