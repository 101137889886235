import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const PropertyGridTile = ({ property }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.open(`/propertydetails`, "_blank");
  };
  return (
    <motion.div
      className="bg-white overflow-hidden cursor-pointer border border-[#0A2342]"
      onClick={handleClick}
      whileHover={{ scale: 1.03 }}
      transition={{ duration: 0.2 }}>
      <div className="relative">
        <div className="absolute top-2 left-2 bg-[#0A2342] text-white text-xs font-semibold px-4 py-1">
          {property.status}
        </div>
        <img
          src={property.image}
          alt={property.name}
          className="w-full h-48 object-cover"
        />
      </div>
      <div className="p-4">
        <h3 className="text-lg font-semibold text-[#0A2342]">
          {property.name}
        </h3>
        <p className="text-sm text-gray-600">{property.address}</p>
        <p className="text-sm font-bold text-[#0A2342] mt-2">
          ${property.price.toLocaleString()}
        </p>
        <p className="text-sm text-gray-600 mt-1">
          {property.bedrooms} Beds • {property.bathrooms} Baths
        </p>
      </div>
    </motion.div>
  );
};

export default PropertyGridTile;
